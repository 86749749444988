import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/scss/styles.css"
import "../assets/scss/tour-list.css"

import Header from '../components/header'
import Footer from '../components/footer'

import header1 from "../assets/img/404.webp"

import countriesStays from '../data/staysFull'

const Stays = ({ location }) => {
  const search = location && location.search ? JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {}

	const [countryFilter, setCountryFilter] = useState(search.country)
	const [cityFilter, setCityFilter] = useState(search.city)
	const [durationFilter, setDurationFilter] = useState(search.duration)
	const [categoryFilter, setCategoryFilter] = useState(search.category)
	const [priceFilter, setPriceFilter] = useState(search.price)

	useEffect(() => {
		if (countryFilter)
			setCityFilter('')
	}, [countryFilter])

  return (
    <main>
			<Helmet>
				<meta charSet="utf-8" />
				<title>CoTraveling - Nos séjours</title>
				<meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
			</Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <div class="image_bg" style={{backgroundImage: `url(${header1})`}}></div>
        <div class="page_content three-columns">
					<div class="wrap">
						<div class="wrap_float">
              <div class="section-subtitle">Voici les séjours que nous proposons</div>
							<div class="main">
								<div class="search_tour">
									<div class="search_tour_form">
										<div class="fields__block">
											<div class="fields">
												<div class="field">
													<div class="label">Pays</div>
													<div class="field_wrap select_field left">
														<select name="tour-activity" value={countryFilter} onChange={(e) => setCountryFilter(e.currentTarget.value)}>
															<option value=''>-</option>
															{countriesStays.map(country => country.cities.filter(city => city.stays.length).length ? <option key={country.slug} value={country.slug}>{country.name}</option> : '')}
														</select>
													</div>
												</div>
												<div class="field">
													<div class="label">Ville</div>
													<div class="field_wrap select_field">
														<select name="tour-activity" value={cityFilter} onChange={(e) => setCityFilter(e.currentTarget.value)}>
															<option value=''>-</option>
															{countriesStays.map(country => country.cities.map(city => (!countryFilter || countryFilter === country.slug) && city.stays.length ? <option key={city.slug} value={city.slug}>{city.name}</option> : ''))}
														</select>
													</div>
												</div>
												<div class="field">
													<div class="label">Durée</div>
													<div class="field_wrap select_field">
														<select name="tour-activity" value={durationFilter} onChange={(e) => setDurationFilter(e.currentTarget.value)}>
															<option value=''>-</option>
															<option value="9">9 nuits</option>
															<option value="10">10 nuits</option>
														</select>
													</div>
												</div>
												<div class="field">
													<div class="label">Catégorie</div>
													<div class="field_wrap select_field">
														<select name="tour-activity" value={categoryFilter} onChange={(e) => setCategoryFilter(e.currentTarget.value)}>
															<option value=''>-</option>
															{/*<option value="classic">Classic</option>*/}
															{/*<option value="silver">Silver</option>*/}
															<option value="gold">Gold</option>
															<option value="platinium">Platinium</option>
															<option value="diamond">Diamond</option>
														</select>
													</div>
												</div>
												<div class="field">
													<div class="label">Prix</div>
													<div class="field_wrap select_field right">
														<select name="tour-activity" value={priceFilter} onChange={(e) => setPriceFilter(e.currentTarget.value)}>
															<option value=''>-</option>
															<option value="1790">1790 €</option>
															<option value="1980">1980 €</option>
															<option value="2170">2170 €</option>
															<option value="2360">2360 €</option>
															<option value="2550">2550 €</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="most_popular__section">
									{countriesStays
										.filter(country => !countryFilter || countryFilter === country.slug)
										.flatMap(country => country.cities
											.filter(city => !cityFilter || cityFilter === city.slug)
											.flatMap(city => city.stays))
												.map(stay => (
													(!durationFilter || stay.datas.nights === parseInt(durationFilter)) &&
													(!categoryFilter || stay.datas.category === categoryFilter) &&
													(!priceFilter || stay.datas.price === parseInt(priceFilter)) ?
														<a href={stay.datas.comingsoon ? '' : stay.url} key={stay.name} class="slider_item" style={{backgroundImage: `url(${stay.images.background})`, pointerEvents: stay.datas.comingsoon ? 'none' : ''}}>
															{stay.datas.comingsoon ? <div class="mslider_info"><span>Bientôt disponible</span></div> : ''}
															{stay.datas.recommand ? <div class="recommand-offer">Nous recommandons</div> : ''}
															<div class="slider_item__content">
																{stay.datas.stars ? <div class="rating">
																	<div class="name">{stay.datas.starsName}</div>
																	<div class="stars">
																		{stay.datas.stars > 0 ? <div class="star active" /> : ''}
																		{stay.datas.stars > 1 ? <div class="star active" /> : ''}
																		{stay.datas.stars > 2 ? <div class="star active" /> : ''}
																		{stay.datas.stars > 3 ? <div class="star active" /> : ''}
																		{stay.datas.stars > 4 ? <div class="star active" /> : ''}
																	</div>
																</div> : ''}  
																<div class="title">
																	{`${stay.name} | ${stay.datas.price} €`}
																</div>
																<div class="days">
																	<span>{`${stay.datas.days} jours | ${stay.datas.nights} nuits`}</span>
																</div>
															</div>
														</a>
													: ''
									))}
								</div>
								{/*<div class="pagination">
									<ul>
										<li class="prev"><a href="#"></a></li>
										<li class="current"><a href="#">1</a></li>
										<li class="next"><a href="#"></a></li>
									</ul>
								</div>*/}
							</div>
						</div>
					</div>
        </div>
				<Footer pathname={location.pathname} />        
      </div>
    </main>
  )
}

export default Stays
